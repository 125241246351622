//@ts-nocheck
/* eslint-disable @next/next/no-html-link-for-pages */
import { FC, useEffect, useState, useRef, createRef, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setBookingResponse, setBookingInfo } from 'redux/features/booking/bookingSlice';
import { bookingEvents } from 'functions/bookingEvents';
import { format, parse } from "date-fns";
import router, { useRouter } from 'next/router';
import Cookies from 'js-cookie';
// import { NextRequest } from 'next/server'
import 'react-phone-input-2/lib/style.css';
import UIButton from 'components/Others/UIButton';
import DateTimeIndicator from './Indicators/DateTimeIndicator';
import ProviderIndicator from './Indicators/ProviderIndicator';
import ServiceIndicator from './Indicators/ServiceIndicator';
import StoreIndicator from './Indicators/StoreIndicator';
import UIRadio from 'components/Others/UIRadio';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UIRadiowButton from 'components/Others/UIRadiowButton';
import Calendar from 'components/Icons/Calendar';
import UserIcon from 'components/Icons/User';
import MultiUserIcon from 'components/Icons/MultiUser';
import dynamic from 'next/dynamic';
// import PhoneNumberInputController from 'components/PhoneNumber/BookingPhoneNumberInput';
const PhoneNumberInputController = dynamic(
  () => import('components/PhoneNumber/BookingPhoneNumberInput')
);


const MySwal = withReactContent(Swal)

//DateOfBirthComponent gets dateOfBirth and setDateOfBirth useState handles to set parent values
function DateOfBirthComponent(
  {
    field,
    dateOfBirth,
    setDateOfBirth,
    day,
    month,
    year,
    register,
    clearErrors
  }
) {


  const padLeft = (item: any) => {
    return item.toString().length == 1 ? "0" + item : item;
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length == 0) return;
    if (e.target.id == "day") {

      if (Number(e.target.value) > 31 || e.target.value == "00" || e.target.value.length == 3) {
        e.currentTarget.value = e.currentTarget.value.split("")[0];
      }

      setDateOfBirth({
        ...dateOfBirth,
        day: padLeft(e.currentTarget.value)
      })
    }

    if (e.target.id == "month") {

      if (Number(e.currentTarget.value) > 12 || e.target.value == "00" || e.target.value.length == 3) {
        e.currentTarget.value = e.currentTarget.value.split("")[0];
      }

      setDateOfBirth({
        ...dateOfBirth,
        month: padLeft(e.currentTarget.value)
      })
    }

    if (e.target.id == "year") {

      if (Number(e.currentTarget.value) > new Date().getFullYear() || Number(e.target.value) == 0 || e.currentTarget.value.length == 5) {
        e.currentTarget.value = e.currentTarget.value.split("")[0];
      }

      setDateOfBirth({
        ...dateOfBirth,
        year: e.currentTarget.value
      })
    }
  };

  const isDatePast = (date: any) => {

    const yearDifference = Math.abs(new Date(parse(dateOfBirth.month + "/" + dateOfBirth.day + "/" + dateOfBirth.year, "MM/dd/yyyy", new Date())).getFullYear() - new Date(parse('1923-01-01', "yyyy-MM-dd", new Date())).getFullYear());

    if (
      new Date(parse(date, "dd-MM-yyyy", new Date())) instanceof Date && !isNaN(Number(new Date(parse(date, "dd-MM-yyyy", new Date())))) &&
      yearDifference < 100 &&
      dateOfBirth.year.length == 4 &&
      dateOfBirth.day.length >= 1 &&
      dateOfBirth.month.length >= 1 &&
      Number(dateOfBirth.day) <= 31 &&
      Number(dateOfBirth.day) > 0 &&
      Number(dateOfBirth.month) <= 12 &&
      Number(dateOfBirth.month) > 0
    ) {
      const before = new Date(parse(date, "dd-MM-yyyy", new Date()));
      const now = new Date();

      return before < now ? true : false;
    } else {
      return false;
    }

  };

  return (<div className="flex">
    <input {...register(field.name, { required: field.is_required, validate: isDatePast })} id={field.name} type="hidden" className='border hidden border-brand-grey400 rounded-[6px] w-full max-w-[468px] h-12 outline-[#243c5a] pr-2' max={new Date().toISOString().split("T")[0]} style={{ textIndent: '8px', 'WebkitAppearance': 'textfield', 'MozAppearance': 'textfield' }} />
    <input
      onChange={handleDateChange}
      placeholder="dd"
      id="day"
      name="day"
      type="number"
      ref={day}
      onWheel={(e) => e.currentTarget.blur()}
      onKeyPress={(event) => {
        clearErrors(field.name)
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      className="rounded-md focus:outline-none w-24 mr-2 border border-solid border-[#CACACA] h-[48px] text-center appearance-[textfield]"
    />
    <input
      onChange={handleDateChange}
      placeholder="mm"
      id="month"
      name="month"
      type="number"
      ref={month}
      onWheel={(e) => e.currentTarget.blur()}
      onKeyPress={(event) => {
        clearErrors(field.name)
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      className="rounded-md focus:outline-none w-24 mr-2 border border-solid border-[#CACACA] h-[48px] text-center appearance-[textfield]"
    />
    <input
      onChange={handleDateChange}
      placeholder="yyyy"
      id="year"
      ref={year}
      name="year"
      type="number"
      onWheel={(e) => e.currentTarget.blur()}
      onKeyPress={(event) => {
        clearErrors(field.name)
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      className="rounded-md focus:outline-none w-24 mr-2 border border-solid border-[#CACACA] h-[48px] text-center appearance-[textfield]"
    />
  </div>);
}

// Add this validation helper near the top of the component
const validateRequired = {
  required: true,
  pattern: {
    value: /^(?!\s*$).+/,
    message: "This field cannot be empty"
  },
  validate: {
    notOnlyWhitespace: (value: string) => value.trim().length > 0 || "This field cannot be empty"
  }
};

export default function ConfirmSelection(props: any) {
  const [bookingType, setBookingType] = useState('');

  const { register, handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm();

  const [dateOfBirth, setDateOfBirth] = useState<any>({
    day: "",
    month: "",
    year: ""
  });

  const year = useRef<any>();
  const month = useRef<any>();
  const day = useRef<any>();

  //const name = useRef<any>(createRef());
  //const email = useRef<any>(createRef());
  //const phone = useRef<any>(createRef());
  const [phoneUpdateIdx, setPhoneUpdateIdx] = useState(0);

  //const [country, setCountry] = useState<any>(null);
  //const [localPhone, setLocalPhone] = useState<any>(null);
  const [componentKey, setComponentKey] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  //const elementsRef = useRef<any>([...props.additionalFields?.map(() => createRef())]);
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null;
  const dispatch = useDispatch();



  const selectedStore = useSelector((state: any) => state.bookingEvent.selectedStore);
  const selectedService = useSelector((state: any) => state.bookingEvent.selectedService);
  const selectedProvider = useSelector((state: any) => state.bookingEvent.selectedProvider);
  const selectedTime = useSelector((state: any) => state.bookingEvent.selectedTime);
  const authToken = useSelector(
    (state: any) => state.authEvent.authToken
  );

  const dob: any = props.additionalFields.find((item: any) => item.title.indexOf('Birth') > -1)
  const medicare: any = props.additionalFields.find((item: any) => item.title.indexOf('Medicare') > -1)
  const irn: any = props.additionalFields.find((item: any) => item.title.indexOf('IRN') > -1)

  const [userMedicare, setUserMedicare] = useState<any>(null);

  const onSubmit = (data: any) => {

    if (data[medicare?.name] && data[irn?.name].length == 0) {
      return MySwal.fire({
        text: 'Please enter IRN number',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn bg-black font-medium text-white hover:text-brand-orange m-3 pl-5 pr-5 p-2.5 rounded-md ml-2',
          htmlContainer: 'font-medium text-black'
        },
        buttonsStyling: false,
        reverseButtons: true,
      })
    }

    if (data[irn?.name] && data[medicare?.name].length == 0) {
      return MySwal.fire({
        text: 'Please enter Medicare number',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn bg-black font-medium text-white hover:text-brand-orange m-3 pl-5 pr-5 p-2.5 rounded-md ml-2',
          htmlContainer: 'font-medium text-black'
        },
        buttonsStyling: false,
        reverseButtons: true,
      })
    }

    const country = localStorage.getItem('country') ? JSON.parse(localStorage.getItem('country') as string) : null;
    const ip = Cookies.get("user-ip") ?? "";
    // console.log(NextRequest);
    if (data.agreement) {
      setIsSubmitting(() => true)
      let requestBody: any = {
        "client_name": data?.name,
        "client_email": data?.email,
        "client_phone": data?.phone,
        "location_id": selectedStore?.id,
        "service_id": selectedService?.id,
        "provider_id": selectedProvider?.id,
        "start_datetime": format(selectedTime, 'yyyy-MM-dd HH:mm:ss'),
        "promotion": data?.promotion,
        "country": country,
        "ip": ip
      }

      if (selectedProvider?.id == 0) {
        requestBody['provider_ids'] = selectedStore.providers;
      }

      requestBody = Object.assign({}, requestBody, { 'additional_fields': Object.assign({}, ...props.additionalFields.map((item: any) => { return { [item.name]: data[item.name] } })) })

      if (JSON.parse(localStorage.getItem('user') as string)?.customer_id && bookingType == 'myself') {
        requestBody['client_id'] = JSON.parse(localStorage.getItem('user') as string)?.customer_id;
      }

      axios.post(process.env.NEXT_PUBLIC_LARAVEL_URL + '/bookings/book', requestBody, {
        headers: {
          'region': router.locale as string
        }
      }).then((response) => {
        dispatch(setBookingResponse(response.data.data))
        dispatch(setBookingInfo(requestBody));
        bookingEvents.Booking5_Success(selectedProvider, selectedService, selectedStore, requestBody, response.data.data, props.additionalFields);
        props.setActivePage(() => 6)
        router.push('#confirm')
        setIsSubmitting(() => false);
      }).catch((error) => {
        MySwal.fire({
          text: error.response.data.error_message || error.response.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn bg-black font-medium text-white hover:text-brand-orange m-3 pl-5 pr-5 p-2.5 rounded-md ml-2',
            htmlContainer: 'font-medium text-black'
          },
          buttonsStyling: false,
          reverseButtons: true,
        })
        //console.log("error -> ", error)
      }).finally(() => {
        setIsSubmitting(() => false);
      });

    }
  };

  useEffect(() => {
    setComponentKey(() => componentKey + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.additionalFields])

  useEffect(() => {

    ///bookings/get-user-medicare
    if (authToken) {
      axios.get(process.env.NEXT_PUBLIC_LARAVEL_URL + '/bookings/get-user-medicare', {
        headers: {
          'region': router.locale as string,
          'Authorization': 'Bearer ' + authToken
        }
      }).then((response) => {
        //console.log("response medicare -> ", response?.data?.[0]?.member_id)
        if (response?.data?.[0] && Number(response?.data?.[0].member_id) && Number(response?.data?.[0].reference)) {
          setUserMedicare(() => response?.data?.[0])
          //setValue("medicare", response.data.data)

        }
      }).catch((error) => {
        console.log(error);
      })
    }

    bookingEvents.Booking4_StartConfirmation(selectedProvider, selectedService, selectedStore, bookingType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  useEffect(() => {
    if (user && bookingType == 'myself') {
      setValue("name", user?.display_name)
      setValue("email", user?.email)
      setValue("phone", user?.phone)
      setPhoneUpdateIdx(() => phoneUpdateIdx + 1)
      // setLocalPhone(() => user?.phone);
      if (dob && user?.birth_date) {
        //setValue(dob.name, user?.birth_date)
        const birthDate = user?.birth_date.split(' ')[0].split('-')


        year.current.value = birthDate[0];
        month.current.value = birthDate[1];
        day.current.value = birthDate[2];

        setDateOfBirth({
          year: birthDate[0],
          month: birthDate[1],
          day: birthDate[2],
        })

        //setValue(dob.name, user?.birth_date)
        setValue(dob.name, dateOfBirth.day + "-" + dateOfBirth.month + "-" + dateOfBirth.year)
        clearErrors(dob.name)
      }


      if (userMedicare && medicare && irn) {
        setValue(medicare.name, userMedicare.member_id)
        setValue(irn.name, userMedicare.reference)
      }
      //setValue(, user?.phone)
      //name.current.value = user?.display_name;
      //email.current.value = user?.email;
      //phone.current.value = user?.phone;
    }

    if (bookingType == 'other') {
      setValue("name", "")
      setValue("email", "")
      setValue("phone", "0")
      setPhoneUpdateIdx(() => phoneUpdateIdx + 1)
      //setLocalPhone(() => "")
      if (dob && user?.birth_date) {
        setValue(dob.name, "")
        setDateOfBirth({
          day: "",
          month: "",
          year: ""
        });
        year.current.value = "";
        month.current.value = "";
        day.current.value = "";
      }

      if (medicare) {
        setValue(medicare.name, "")
      }

      if (irn) {
        setValue(irn.name, "")
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingType, userMedicare])



  useEffect(() => {
    if (dob) {
      setValue(dob.name, dateOfBirth.day + "-" + dateOfBirth.month + "-" + dateOfBirth.year)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfBirth.year, dateOfBirth.month, dateOfBirth.day])

  const formInputKeywordsByBookingType = {
    myself: {
      name: router.locale == 'au' ? 'Your Name and Surname <span class="text-xs text-brand-grey350">(as on Medicare if available)</span>' : 'Your Name',
      email: 'Your Email',
      phone: 'Your Phone',
    },
    other: {
      name: router.locale == 'au' ? 'Patient’s Name and Surname <span class="text-xs text-brand-grey350">(as on Medicare if available)</span>' : "Patient's Name and Surname",
      email: "Patient's Email",
      phone: "Patient's Phone",
    }
  }


  return (
    <div className='mt-5 lgx:px-2'>
      <h2 className=' lg:text-2xl lgx:text-lg font-bold'>Please enter the patient details</h2>

      <div className="flex justify-between lgx:flex-col">
        <div className='mt-4 min-w-[70%] lgx:min-w-full lg:mb-4  border text-sm border-brand-grey500 rounded-xl lgx:mb-4'>
          <form className='p-5' onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-6 mt-4'>
              <div className=" font-medium mr-36 mb-4 lgx:mb-2">For whom are you booking? </div>
              <UIRadiowButton
                options={[
                  { text: 'Myself', value: 'myself', icon: <UserIcon fill={bookingType == 'myself' ? 'white' : '#959595'} /> },
                  { text: 'Someone else', value: 'other', icon: <MultiUserIcon fill={bookingType == 'other' ? 'white' : '#959595'} /> }
                ]}
                value={bookingType}
                onChange={setBookingType}
              />
            </div>
            {(bookingType == 'myself' || bookingType == 'other') && <span>

              {bookingType == 'myself' && <div className="mb-8">If you are making an appointment for yourself, please enter YOUR name, {router.locale != 'ca' && 'DOB and Medicare details,'} and contact details. </div>}
              {bookingType == 'other' && <div className="mb-8">If you are making an appointment for a child or someone in your care, please enter THEIR name, {router.locale != 'ca' && 'date of birth and Medicare details,'} and your contact details. Please put your name in the notes field. </div>}

              <div className='lg:flex justify-between items-center mb-8 max-w-[468px]'>
                <div className=" font-medium"><span dangerouslySetInnerHTML={{ __html: formInputKeywordsByBookingType[bookingType]['name'] }}></span> <span className="text-brand-red">*</span></div>
                <div><input 
                  {...register('name', validateRequired)} 
                  disabled={user && bookingType === 'myself'} 
                  className="lg:w-80 lgx:w-full lg:ml-4 lgx:mt-2 h-12 border border-brand-grey400 rounded-[6px] outline-[#243c5a]" 
                  style={{ textIndent: '8px' }} 
                  placeholder='Name and surname' 
                />{errors['name'] && <div className="lg:pl-4 absolute text-brand-red">This field is required</div>}</div>
              </div>


              <div className='lg:flex justify-between items-center mb-8 max-w-[468px]'>
                <div className=" font-medium">{formInputKeywordsByBookingType[bookingType]['email']} <span className="text-brand-red">*</span></div>
                <div><input 
                  {...register('email', {
                    ...validateRequired,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address"
                    }
                  })} 
                  disabled={user && bookingType === 'myself'} 
                  className="lg:w-80 lgx:w-full lg:ml-4 lgx:mt-2 h-12 border border-brand-grey400 rounded-[6px] outline-[#243c5a]" 
                  style={{ textIndent: '8px' }} 
                  placeholder="Email" 
                  type="email" 
                />{errors['email'] && <div className="lg:pl-4 absolute text-brand-red">This field is required</div>}</div>
              </div>
              <div className='lg:flex justify-between items-center mb-5 mt-3 max-w-[468px]'>
                <div className=" font-medium lgx:mb-2 whitespace-nowrap lg:mr-9 w-[162px]">{formInputKeywordsByBookingType[bookingType]['phone']} <span className="text-brand-red">*</span></div>
                <div>
                  <PhoneNumberInputController
                    key={phoneUpdateIdx}
                    name="phone"
                    control={control}
                    required
                    onChange={phone => {
                      setValue("phone", phone)
                    }}
                  />
                  {errors['phone'] && <div className="pb-2 absolute text-brand-red">This field is required</div>}
                </div>
              </div>
              <div className='border-t-[1px] border-brand-grey200 pt-10'>
                {props.additionalFields && props.additionalFields.map((field: any, idx: any) => {
                  return (
                    <div className='mb-7' key={idx} >
                      <div className='mb-2 font-medium'>{field.title.includes("OHIP Reference") ? "OHIP Version Code" : field.title} {field.is_required && <span className="text-brand-red">*</span>}</div>
                      {(field.title.indexOf('Birth') > -1 && field.type == 'text') && <DateOfBirthComponent field={field} dateOfBirth={dateOfBirth} setDateOfBirth={setDateOfBirth} day={day} month={month} year={year} register={register} clearErrors={clearErrors} />}
                      {!(field.title.indexOf('Birth') > -1) && field.title.indexOf('OHIP Reference') === -1 && field.type === "text" && <input {...register(field.name, field.is_required ? validateRequired : {})} id={field.name} type={field.type} className='border border-brand-grey400 rounded-[6px] w-full max-w-[468px] h-12 outline-[#243c5a] pr-2' style={{ textIndent: '8px' }} />}
                      {(field.title.includes("OHIP Reference")) && field.type === "text" && <input maxLength={2} minLength={1} onChangeCapture={(event) => {
                        // console.log('event', event);
                        event.target.value = event.target.value.toUpperCase();
                      }} onKeyPress={(event) => {
                        if (!/[a-zA-Z]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} {...register(field.name, { required: field.is_required })} id={field.name} type={field.type} className='border border-brand-grey400 rounded-[6px] w-full max-w-[468px] h-12 outline-[#243c5a] pr-2' required={field.is_required} style={{ textIndent: '8px' }} />}
                      {!field.title.includes("OHIP Number") && field.type === "digits" && <input {...register(field.name, { required: field.is_required })} onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} {...register(field.name, { required: field.is_required })} maxLength={field.title.indexOf('IRN') > -1 ? 1 : (field.title.indexOf('How old is the person') > -1 ? 2 : 10)} minLength={field.title.indexOf('IRN') > -1 ? 1 : (field.title.indexOf('How old is the person') > -1 ? 1 : 10)} id={field.name} type={field.type} className='border border-brand-grey400 rounded-[6px] w-full max-w-[468px] h-12  outline-[#243c5a]' required={field.is_required} style={{ textIndent: '8px' }} />}
                      {field.title.includes("OHIP Number") && field.type === "digits" && <input {...register(field.name, {
                        required: field.is_required, pattern: {
                          value: /^\d{4}-?\d{3}-?\d{3}$/, // replace with your regex
                          message: "Invalid OHIP Number format" // replace with your error message
                        }
                      })} onKeyPress={(event) => {
                        if (!/[0-9-]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} {...register(field.name, { required: field.is_required })} maxLength={12} minLength={10} id={field.name} type={field.type} className='border border-brand-grey400 rounded-[6px] w-full max-w-[468px] h-12  outline-[#243c5a]' required={field.is_required} style={{ textIndent: '8px' }} />}
                      {field.type === "textarea" && <textarea id={field.name} {...register(field.name, field.is_required ? validateRequired : {})} className='block border border-brand-grey400 rounded-[6px] w-full h-32 max-w-[468px] outline-[#243c5a] pt-2' style={{ textIndent: '8px' }} />}
                      {errors[field.name] && <span className="text-brand-red">{field.title.includes("OHIP Number") ? errors[field.name]?.message?.toString() : `This field is required`} </span>}
                    </div>
                  );
                })}
              </div>
              <div className='mb-3 flex'>
                <input type='checkbox' {...register('agreement', { required: true })} id="agreement" className='dresden-checkbox' /><label htmlFor='agreement' className="ml-8">I agree with Dresden Vision <a href="/terms-and-conditions" target="_blank" className="text-brand-blue">Terms & Conditions</a></label>
              </div>
              {errors['agreement'] && <div className="text-brand-red -mt-2 mb-4">This field is required</div>}
              {(!user || user?.new_opt_in == "NONE" || user?.new_opt_in == "HEALTH") && <div className='mb-7 flex'>
                <input type='checkbox' {...register('promotion')} id="promotion" className='dresden-checkbox' /><label htmlFor='promotion' className="ml-8">Be one of the first to receive messages about our promotions and other cool stuff.</label>
              </div>}

              <div><UIButton className="mt-10 mb-4" loading={isSubmitting} onClick={() => {
                //let formResult = elementsRef.current.map((item:any) => { return {[item.current.id]:item.current.value} })
                //console.log(formResult)
                //props.setActivePage(6)
                //handleBooking();
              }}>Confirm Booking</UIButton></div>
            </span>}
          </form>

        </div>
        <div className='w-full h-full mt-4'>
          <DateTimeIndicator setActivePage={props.setActivePage} />
          <ProviderIndicator setActivePage={props.setActivePage} />
          <ServiceIndicator setActivePage={props.setActivePage} />
          <StoreIndicator setActivePage={props.setActivePage} />
        </div>
      </div>
    </div>
  );
} 